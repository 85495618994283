import React, { useState, useEffect } from "react"
import { Button, Spin } from "antd"

import Seo from "../../components/seo"
import MainLayout from "../../Layouts/layoutLanding"
import CheckIcon from "../../assets/images/ic_check_circle.svg"

import "./BookingConfirmation.scss"

const BookingConfirmation = ({ data, pageContext }) => {
  const [bookerName, setBookerName] = useState<string | null>("")
  const [referenceNumber, setReferenceNumber] = useState<string | null>("")
  const [reservationId, setReservationId] = useState<string | null>("")
  const [totalAmount, setTotalAmount] = useState<string | null>("")
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const queryString = window.location.href
    const urlParams = new URLSearchParams(queryString)

    setBookerName(urlParams.get("name"))
    setReferenceNumber(urlParams.get("reference_no"))
    setReservationId(urlParams.get("reservation_id"))
    setTotalAmount(urlParams.get("amount"))

    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  const bookingMessage = referenceNumber ? (
    <p>Your booking is now confirmed and fully-paid.</p>
  ) : (
    <p>
      Your booking is now confirmed. <br /> This is a pay at hotel booking and
      full payment must be made at the hotel.
    </p>
  )
  const paymentInfo = (
    <div>
      Payment ID.: <span className="confirmation-value">{referenceNumber}</span>
    </div>
  )

  const findHotelOpen = () => {
    if (typeof window !== "undefined") {
      const ele = document.getElementById("findhotelFullscreen")

      if (ele) document.body.style.overflow = "hidden"
      return ele?.classList.add("active")
    }
  }

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" spinning={true} />
      </div>
    )
  } else {
    return (
      <MainLayout
        pageContext={pageContext}
        title="booking inside-pages"
        isBookingLayout
      >
        <Seo title="Booking Confirmation" lang="en" />
        <div className="confirmation-container">
          <div className="confirmation-modal">
            <div className="confirmation-check-icon">
              <img src={CheckIcon} alt="check icon" />
            </div>
            <h1 className="confirmation-name">Thank you {bookerName}!</h1>
            {bookingMessage}
            <div className="confirmation-details">
              <div>
                Booking Reservation ID:{" "}
                <span className="confirmation-value">{reservationId}</span>
              </div>
              {referenceNumber ? paymentInfo : null}
              <div>
                Total Amount:{" "}
                <span className="confirmation-value">Php {totalAmount}</span>
              </div>
            </div>
            <p className="confirmation-reminders">
              Please note your booking reference number for any booking-related
              transactions.
              <br />
              Check your email for the complete details of your stay. Contact
              the hotel for any concerns.
            </p>
            <Button
              size="large"
              type="primary"
              className="confirmation-main-btn"
              onClick={findHotelOpen}
            >
              MAKE ANOTHER BOOKING
            </Button>
            <a href="/" className="confirmation-sub-btn">
              Return to homepage
            </a>
          </div>
        </div>
      </MainLayout>
    )
  }
}

export default BookingConfirmation
